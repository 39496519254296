import { colorTokens, type Skin } from '@orus.eu/pharaoh'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.svg'

const frauncesUrl =
  'https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,400;9..144,500;9..144,600&display=swap'
const satoshiUrl = 'https://fonts.cdnfonts.com/css/satoshi'
const satoshiFamily = 'satoshi'

export const sidecareSkin: Skin = {
  quoteCardHelperBackgroundColor: colorTokens['color-bg-decorative-1'],
  avatarUrl,
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  horizontalLogo: {
    aspectRatio: 274 / 48,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colorTokens['color-bg-base-normal'],
    backgroundColor: colorTokens['color-text-decorative-2'],
  },
  subscriptionCalloutColors: {
    backgroundColor: '#6D092A',
    accentColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
  stripe: {
    fontFamily: satoshiFamily,
    fontFamilyUrl: satoshiUrl,
  },
  additionalStylesheets: [frauncesUrl, satoshiUrl],
  cssVariables: {
    '--skin-blue-100': '#FFFDF9',
    '--skin-blue-200': '#FFE5DA',
    '--skin-blue-300': '#FFB08F',
    '--skin-blue-400': '#FF8857',
    '--skin-blue-500': '#FF7B44',
    '--skin-blue-600': '#EB662E',
    '--skin-blue-700': '#D2531E',
    '--skin-blue-800': '#301111',
    '--skin-blue-900': '#0F0F0F',
    '--skin-gray-100': '#F3F3F3',
    '--skin-gray-200': '#E7E7E7',
    '--skin-gray-300': '#CFCFCF',
    '--skin-gray-400': '#CFCFCF',
    '--skin-gray-500': '#9F9F9F',
    '--skin-gray-600': '#6F6F6F',
    '--skin-gray-700': '#636363',
    '--skin-gray-800': '#3F3F3F',
    '--skin-gray-900': '#0F0F0F',
    '--skin-green-100': '#EBF9F1',
    '--skin-green-200': '#D8F3E2',
    '--skin-green-300': '#89DAA8',
    '--skin-green-400': '#61CD88',
    '--skin-green-500': '#3AC26E',
    '--skin-green-600': '#28A156',
    '--skin-green-700': '#00752D',
    '--skin-green-800': '#005922',
    '--skin-green-900': '#003715',
    '--skin-yellow-100': '#FFF7DF',
    '--skin-yellow-200': '#FFF7DF',
    '--skin-yellow-300': '#FFF1C9',
    '--skin-yellow-400': '#FEEBB4',
    '--skin-yellow-500': '#FEE394',
    '--skin-yellow-600': '#EFCE6F',
    '--skin-yellow-700': '#D1AA36',
    '--skin-yellow-800': '#846710',
    '--skin-yellow-900': '#5A4301',
    '--skin-orange-100': '#FFF6EE',
    '--skin-orange-200': '#FFEDDD',
    '--skin-orange-300': '#FFDBBB',
    '--skin-orange-400': '#FFB778',
    '--skin-orange-500': '#FFA656',
    '--skin-orange-600': '#FF7B44',
    '--skin-orange-700': '#D85D29',
    '--skin-orange-800': '#8B320E',
    '--skin-orange-900': '#471905',
    '--skin-red-100': '#FFEFEF',
    '--skin-red-200': '#FEDFDF',
    '--skin-red-300': '#FDC0C0',
    '--skin-red-400': '#FB8181',
    '--skin-red-500': '#FB6161',
    '--skin-red-600': '#CB2828',
    '--skin-red-700': '#8F0D0D',
    '--skin-red-800': '#5B0000',
    '--skin-red-900': '#410000',
    '--skin-white': '#FFFFFF',
    '--skin-black': '#000000',
    '--skin-linen': '#FFFAF2',
    '--skin-sky-dark': '#6D092A',
    '--skin-sky-light': '#6D092A',
    // we use a gradient, even if its flat for this theme, because the variable is used as a background image
    '--skin-sky-gradient': 'radial-gradient(400% 100% at 0% 100%, #6D092A 0%, #6D092A 100%)',
    '--skin-periwinkle-dark': '#DED1F9',
    '--skin-periwinkle-light': '#F1EBFC',
    '--skin-periwinkle-gradient': 'radial-gradient(400% 100% at 0% 100%, #DED1F9 0%, #E1D8F8 100%)',
    '--skin-mindaro-dark': '#E1F5A8',
    '--skin-mindaro-light': '#EFF9CE',
    '--skin-mindaro-gradient': 'radial-gradient(400% 100% at 0% 100%, #E1F5A8 0%, #E4F5B5 100%)',
    '--skin-jasmine-dark': '#FFECA8',
    '--skin-jasmine-light': '#FFF8DE',
    '--skin-jasmine-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFECA8 0%, #FFF1BE 100%)',
    '--skin-peach-dark': '#FFC4A2',
    '--skin-peach-light': '#FFE6D5',
    '--skin-peach-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFC4A2 0%, #FFD0B4 100%)',
  },
}
